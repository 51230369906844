import React, { Fragment } from 'react';
import PageTitle from '../components/PageTitle';
import Section from '../components/Section';

export default () => (
  <Fragment>
    <PageTitle title="Contact" light />
    <Section light>Your message has been sent.</Section>
  </Fragment>
);
